import Vue from 'vue'
import VueRouter from 'vue-router'
import yiyoutong from '../views/index.vue'

Vue.use(VueRouter)

const routes = [
    {
    path: '/',
    redirect: "/yiyoutong"
  },
  {
    path: '/yiyoutong',
    name: 'yiyoutong',
    component: yiyoutong
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
