<template>
	<div class="page">
		<!-- <div class="titleLogo">
			<img src="./assets/img/logo.png" alt="" class="logo" />
		</div> -->
		<div class="banner">
			<img src="./assets/img/bannerBg.png" class="bannerBg" alt="" />
			<div class="banner_title ">
				<p class="mb23"><span class="fz40 fw8 animated bounce">便捷、高效、明白</span>就医</p>
				<p>从基础信息建设，走向<span class="fz40 fw8">智慧服务运营</span></p>
			</div>
		</div>
		<!-- 公司介绍 -->
		<div class="contentBlock">
			<div class="company">
				<div class="companyTitle">
					<div class="fz36 fw8 flex-title">
						<img src="./assets/img/left.png" class="iconLeft">
						<p class="pdlr13">公司介绍</p>
						<img src="./assets/img/right.png" class="iconLeft">
					</div>
					<div class="tip">Company Profile</div>
				</div>
				<div class="companyDesc">
					<div class="companyDescContent">
						<div class="fz24 fw8 c2 mb7 flex-top "><img src="./assets/img/logo.png"
								class="w28 mr6" />便捷、高效、明白就医</div>
						<p class="lineHeightDesc">
							北京医邮通信息技术有限公司是北京融威众邦电子技术有限公司全资子公司，基于智慧医疗的信息化之上，与医疗机构深度融合，进行患者就医服务的深化与拓展，搭建简单就医服务平台，利用物联网+、互联网+、AI等新一代信息技术，为医疗机构提供标准规范化的线上线下的运营服务，提高医疗机构的服务效能和质量，努力做到让人民群众便捷就医、高效就医、明白就医，进一步改善人民群众看病就医感受。
						</p>
					</div>
					<div class="companyDescContent">
						<div class="fz24 fw8 c2 mb7 flex-top "><img src="./assets/img/rszblogo.png"
								class="w28 mr6" />用科技服务百姓生活</div>
						<p class="lineHeightDesc">
							北京融威众邦电子技术有限公司成立于2009年，经过10多年发展，成为国内领先的自助服务系统、信息发布系统、互联网+医院产品及服务提供商，服务北京地区75%以上二级医院和全国400多家医院，设备数量过万台，日均分诊超百万次、日均交易超20万笔、日流水过3千万。
						</p>
					</div>
				</div>
			</div>
			<div class="company">
				<div class="companyTitle">
					<div class="fz36 fw8 flex-title">
						<img src="./assets/img/left.png" class="iconLeft">
						<p class="pdlr13">价值主张</p>
						<img src="./assets/img/right.png" class="iconLeft">
					</div>
					<div class="tip">Value Proposition</div>
				</div>
				<div class="companyDesc">
					<div class="valueDescContent">
						<img src="./assets/img/oneBgIcon.png" class="w28 mr6 valueImgP" />
						<div class="fz24 fw8 c2 mb4 tc">功能实用</div>
						<p>· 围绕服务导向,精准定位患者就医的场景需求。利用移动互联网、 物联网手段,丰富补充或升级完善服务。
						</p>
						<p>· 延伸服务半径,点-线-面的服务串联。
						</p>

					</div>
					<div class="valueDescContent">
						<img src="./assets/img/twoBgIcon.png" class="w28 mr6 valueImgP" />
						<div class="fz24 fw8 c2 mb4 tc">就医感受</div>
						<p>· 医疗服务围着患者转，满足患者在院内的便捷、明白、高效就医需求
						</p>
						<p>· 多层次多维度的服务保障。
						</p>

					</div>
					<div class="valueDescContent">
						<img src="./assets/img/threeBgIcon.png" class="w28 mr6 valueImgP" />
						<div class="fz24 fw8 c2 mb4 tc">管理效率</div>
						<p>· 自助化无人值守解决方案，实现降本提效。
						</p>
						<p>· 多功能统一平台管理，助力工作效率提升，化繁为简。
						</p>
						<p>· 数字化赋能决策，实现科学、客观、真实管理。
						</p>
					</div>
					<div class="valueDescContent">
						<img src="./assets/img/fourBgIcon.png" class="w28 mr6 valueImgP" />
						<div class="fz24 fw8 c2 mb4 tc">成本收益</div>
						<p>· 联合运营减少医院投入，改善就医体验，提升患者口碑。
						</p>
						<p> · 标杆效应，差异化医院运营
						</p>
					</div>
				</div>
			</div>
			<div class="company">
				<div class="companyTitle" style="padding-top: 1rem;">
					<div class="fz36 fw8 flex-title">
						<img src="./assets/img/left.png" class="iconLeft">
						<p class="pdlr13">解决方案</p>
						<img src="./assets/img/right.png" class="iconLeft">
					</div>
					<div class="tip">Solution</div>
				</div>
				<div class="companyTitle" style="padding-top: 2.5rem;">
					<div class="fz30 fw8 flex-title">
						<img src="./assets/img/cIconLeft.png" class="cIconLeft">
						<p class="pdlr13">自助复印打印服务</p>
						<img src="./assets/img/cIconRight.png" class="cIconLeft">
					</div>
				</div>
				<div class="companyDesc" style="width: 80%;margin: 0 auto;margin-top: 1.5rem;">
					<div class="solutDescContent">
						<div style="display: flex;margin-bottom: 2.6rem;margin-top: 0.4rem;">
							<img src="./assets/img/cIconTop.png" class="w5 mr6" />
							<div>
								<div class="fz24 fw8 c2 mb4 tc">一站式 7×24h 自助打印</div>
								<div style="margin-top: 0.6rem;">
									<p>· 页面指引用户操作，无需人工值守</p>
									<p style="margin-top: 0.3rem;">· 系统自检，保证稳定</p>
								</div>
							</div>
						</div>
						<div style="display: flex;margin-bottom: 2.6rem;margin-top: 0.4rem;">
							<img src="./assets/img/cIconC.png" class="w5 mr6" />
							<div>
								<div class="fz24 fw8 c2 mb4 tc">覆盖日常复印打印需求</div>
								<div style="margin-top: 0.6rem;">
									<p>· 纸质文件复印</p>
									<p style="margin-top: 0.3rem;">· 证件复印</p>
									<p style="margin-top: 0.3rem;"> · 手机文档/图片打印</p>
								</div>
							</div>
						</div>
						<div style="display: flex;margin-bottom: 2.6rem;margin-top: 0.4rem;">
							<img src="./assets/img/cIconB.png" class="w5 mr6" />
							<div>
								<div class="fz24 fw8 c2 mb4 tc">全场景适配</div>
								<div style="margin-top: 0.6rem;">
									<p>· 医院门急诊、住院 </p>
									<p style="margin-top: 0.3rem;">· 商铺楼宇</p>
									<p style="margin-top: 0.3rem;">· 社区服务</p>
								</div>
							</div>
						</div>
					</div>
					<div class="solutDescContent">
						<img src="./assets/img/zzdyj.png" class="w100 mr6" />
					</div>
				</div>
				<div class="flex itemBettew">
					<div class=" itemThreeStep">
						<div class="tc"><img src="./assets/img/zzfour.png" class="threeStepIcon"></div>
						<div class="tc fz24 fw8">4步快速复印打印</div>
						<div class="fz20 itemList">
							<p>选择功能<img src="./assets/img/jiantou.png"
									style="margin:0 0.3rem;display: inline-block;width: 0.4rem;">扫码连接<img
									src="./assets/img/jiantou.png"
									style="margin:0 0.3rem;display: inline-block;width: 0.4rem;">设置参数</p>
							<p><img src="./assets/img/jiantou.png"
									style="margin:0 0.3rem 0 0 ;display: inline-block;width: 0.4rem;">付款打印</p>
						</div>
					</div>
					<div class=" itemThreeStep">
						<div class="tc"><img src="./assets/img/zzzn.png" class="threeStepIcon"></div>
						<div class="tc fz24 fw8">智能自检</div>
						<div class="fz20 itemList">
							<p>· 硬件自检：核心组件定时自检，出现异常及时通知</p>
							<p>· 耗材自检：检测耗材剩余，提醒补充 </p>
						</div>
					</div>
					<div class=" itemThreeStep">
						<div class="tc"><img src="./assets/img/zzsb.png" class="threeStepIcon"></div>
						<div class="tc fz24 fw8">设备稳定</div>
						<div class="fz20 itemList">
							<p>· 高清成像，还原文件细节</p>
							<p>· 高速稳定商用打印机，保障批量快速打印</p>
							<p>· 内置4G网络，保障通信顺畅</p>
						</div>
					</div>
				</div>
				<div class="companyTitle" style="padding-top: 2.8rem;">
					<div class="fz30 fw8 flex-title">
						<img src="./assets/img/cIconLeft.png" class="cIconLeft">
						<p class="pdlr13">共享明星产品</p>
						<img src="./assets/img/cIconRight.png" class="cIconLeft">
					</div>
				</div>
				<div class=" itemBettewList ">
					<div class=" itemTenStep">
						<div class="tc itemTenStepImg">
							<img src="./assets/img/gxphc.png" class="tenStepIcon">
						</div>
						<div class="tc fz16 c6">共享陪护床</div>
					</div>
					<div class=" itemTenStep">
						<div class="tc itemTenStepImg">
							<img src="./assets/img/gxly.png" class="tenStepIcon">
						</div>
						<div class="tc fz16 c6">共享轮椅</div>
					</div>
					<div class=" itemTenStep">
						<div class="tc itemTenStepImg">
							<img src="./assets/img/gxdjc.png" class="tenStepIcon">
						</div>
						<div class="tc fz16 c6">共享担架车</div>
					</div>
					<div class=" itemTenStep">
						<div class="tc itemTenStepImg">
							<img src="./assets/img/gxphcg.png" class="tenStepIcon">
						</div>
						<div class="tc fz16 c6">共享陪护床柜</div>
					</div>
					<div class=" itemTenStep">
						<div class="tc itemTenStepImg">
							<img src="./assets/img/gxphy.png" class="tenStepIcon">
						</div>
						<div class="tc fz16 c6">共享陪护椅</div>
					</div>
					<div class=" itemTenStep">
						<div class="tc itemTenStepImg">
							<img src="./assets/img/gxetc.png" class="tenStepIcon">
						</div>
						<div class="tc fz16 c6">共享儿童车</div>
					</div>
					<div class=" itemTenStep">
						<div class="tc itemTenStepImg">
							<img src="./assets/img/gxys.png" class="tenStepIcon">
						</div>
						<div class="tc fz16 c6">共享雨伞</div>
					</div>
					<div class=" itemTenStep">
						<div class="tc itemTenStepImg">
							<img src="./assets/img/gxcwg.png" class="tenStepIcon">
						</div>
						<div class="tc fz16 c6">共享储物柜</div>
					</div>
					<div class=" itemTenStep">
						<div class="tc itemTenStepImg">
							<img src="./assets/img/gxcdb.png" class="tenStepIcon">
						</div>
						<div class="tc fz16 c6">共享充电宝</div>
					</div>
					<div class=" itemTenStep">
						<div class="tc itemTenStepImg">
							<img src="./assets/img/gxqdj.png" class="tenStepIcon">
						</div>
						<div class="tc fz16 c6">共享取袋机</div>
					</div>
				</div>
			</div>
			<div class="centerContentBlock">
				<img class="centerContentBlock" src="./assets/img/centerBg.png">
				<div class="centerContentBlockText">
					<p class="fz24 fw8">明星功能：自助紫外线消杀使用更安心</p>
					<p class="fz20 mt50">部分产品如轮椅、平车、陪护床等可设置定时自动紫外线消毒，保证设备放</p>
					<p class="fz20 mt25">心使用。设备全程自动化，节约人力维护成本。</p>
					<p class="fz20 mt25">一站式共享服务解决方案，一次投放全套配齐。</p>
				</div>
			</div>
			<div class="company">
				<div class="companyTitle">
					<div class="fz36 fw8 flex-title">
						<img src="./assets/img/left.png" class="iconLeft">
						<p class="pdlr13">简单就医服务平台</p>
						<img src="./assets/img/right.png" class="iconLeft">
					</div>
					<div class="tip">Service Platform</div>
				</div>
				<div class="" style="margin-top: 1.30rem;">
					<p class="fz24 fw8" style="margin-left: 9%;">面向<span style="color: #1777FF;">医疗机构</span>的</p>
					<p class="fz24 fw8" style="margin-left: 8%;">联合运营解决方案</p>
					<div class="centerPositinBlock">
						<img src="./assets/img/bottomBg.png" style="width: 100%;" />
						<p>院内宣传平台</p>
						<p>便民服务矩阵</p>
						<p>服务管理工具</p>
						<p>就医全攻略</p>
						<p>招募发布媒介</p>
						<p>设备辅助管理</p>
						<p>全病程管理</p>
						<p>便民就医工具</p>
						<p>人工聚合服务</p>
						<p>院内服务指引</p>
					</div>
					<p class="fz24 fw8" style="margin-left: 74%;">面向<span style="color: #1EE3AB;">患者及家属</span>的</p>
					<p class="fz24 fw8" style="margin-left: 76.35%;">就医服务网络</p>
				</div>
			</div>
			<div class="companyTitle" style="padding-top: 2.8rem;">
				<div class="fz30 fw8 flex-title">
					<img src="./assets/img/cIconLeft.png" class="cIconLeft">
					<p class="pdlr13">便捷、高效、明白就医</p>
					<img src="./assets/img/cIconRight.png" class="cIconLeft">
				</div>
			</div>
			<div class="company fz20 tc">
				<p class="mt50">简单就医服务平台定位于围绕患者到院就医的场景，面对陌生的就医环境，帮助患者减少就医过程中的无助，从而提升就医感受。</p>
				<p class="mt25">简单就医平台联合医院共同落地医院便民举措，提供实用、创新的患者服务。</p>
			</div>
			<div class="centerContentBlock" style="height: 12.1rem;margin-top: 4rem;">
				<img class="centerContentBlockImg" src="./assets/img/footerBg.png">
				<div class="centerContentBlockText">
					<p class="fz30 fw8">便捷就医、高效就医、明白就医</p>
					<p class="fz20 mt50 ">公司地址：北京市海淀区中关村南大街12号科</p>
					<p class="fz20 mt25" style="margin-left: 2.5rem;">技综合楼1层106室</p>
					<p class="fz20 mt25">商务合作：18810014484</p>
					<p class="fz20 mt25">联系邮箱：open@91soeasy.com.cn</p>
				</div>
				<div class="wxposi fz20 c0 tc">
					<img src="./assets/img/wxxcx.png" class="footerImg">
					简单就医小程序
				</div>
				<div class="gzhposi fz20 c0 tc">
					<img src="./assets/img/wxgzh.png" class="footerImg">
					简单就医公众号
				</div>
				<div class="gxpoxi fz20 c0 tc">
					<img src="./assets/img/gxfw.png" class="footerImg">
					共享服务
				</div>
				<div style="color: #ffffff !important;position: absolute;bottom: 12%;width: 100%;text-align: center;"
					class="fz16">增值电信业务经营许可证：京B2-20231616</div>
				<div style="color: #ffffff !important;position: absolute;bottom: 7%;width: 100%;text-align: center;"
					class="fz16">药品医疗器械网络信息服务备案：(京)网药械信息备字（2024）第00282号
				</div>
				<div style="color: #ffffff !important;position: absolute;bottom: 2%;width: 100%;text-align: center;"
					class="fz16">Copyright © 2013-2023 91Soeasy 北京医邮通信息技术有限公司 .All Rights Reserved <a
						href="https://beian.miit.gov.cn/#/Integrated/recordQuery" style="color: #ffffff;"
						target="_blank">京ICP备19012496号</a></div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				constants: {}
			};
		},
		methods: {}
	};
</script>
<style scoped lang="css" src="./assets/index.css" />